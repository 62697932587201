<template>
  <div>
    <CCard>
      <CCardHeader>
        <strong>Filtrar por </strong>
        <button
          type="button"
          class="btn-sm btn-info float-right"
          @click="mtd_send_register"
        >
          <i class="fas fa-plus"></i>&nbsp;Nueva salida
        </button>
      </CCardHeader>
      <CCardBody>
        <!-- Para buscar por proyecto-->
        <CRow>
          <CCol lg="4" md="4" sm="12" xs="12">
            <label for="">Elegir detalle</label>
            <v-select
              :options="data_product_entry"
              multiple
              v-model="filter.product_entry"
              placeholder="Seleccione detalle(s)"
            />
          </CCol>
          <CCol lg="4" md="4" sm="12" xs="12">
            <label for="">Elegir destino</label>
            <v-select
              :options="data_destinations"
              v-model="filter.destination"
              placeholder="Seleccione destino(s)"
            />
          </CCol>
          <CCol lg="4" md="4" sm="12" xs="12">
            <label for="">Elegir transportista</label>
            <v-select
              :options="data_transports"
              v-model="filter.transport"
              placeholder="Seleccione transportista(s)"
            />
          </CCol>
          <CCol lg="2" md="2" sm="12" xs="12">
            <label for="">Elegir estado</label>
            <v-select
              :options="data_asig"              
              v-model="filter.asig"
              placeholder="Seleccione"
            />
          </CCol>
          <CCol lg="2" md="2" sm="12" xs="12">
            <CInput
              type="date"
              label="Fecha Inicio"
              v-model="filter.date_initial"
            >
            </CInput>
          </CCol>
          <CCol lg="2" md="2" sm="12" xs="12">
            <CInput
              type="date"
              label="Fecha Fin"
              v-model="filter.date_end"
              :min="filter.date_initial"
            >
            </CInput>
          </CCol>
          <CCol sm="2">
            <label>&nbsp;</label>
            <br />
            <CButton type="submit" color="primary" @click="mtd_search_filter()">
              <i class="fa fa-search"></i> Buscar Lotes
            </CButton>
          </CCol>
          <CCol sm="2"> &nbsp; </CCol>
          <!-- <CCol sm="2"><br>
                        <CButton type="submit" color="success" @click="mtd_export_pdf()">
                            <i class="fa fa-file-pdf"></i> Reporte PDF
                        </CButton>
                    </CCol> -->
          <!-- <CCol sm="2"><br>
                        <CButton type="submit" color="success" @click="mtd_export_excel()" v-if="load.excel == true">
                            <i class="fa fa-file-excel"></i> Reporte EXCEL
                        </CButton>
                    </CCol> -->
        </CRow>
      </CCardBody>
    </CCard>
    <CRow>
      <CCol sm="12">
        <CTableWrapper
          :items="data"
          :fields="fields"
          hover
          :striped="true"
          :border="true"
          small
          fixed
          caption="Lista de lotes"
          icon="fas fa-box"
          btn_name="lote"
          :size="'lg'"
          :button_new="false"
          :actions="true"
          :withActions="'14%'"
          :buttonEdit="false"
          :buttonDelete="true"
          :myButtons="myButtons"
          @mtd_asignar_lote="mtd_asignar_lote"
          @mtd_reg_entrega="mtd_reg_entrega"
          @mtd_set_valore_proceso="mtd_set_valore_proceso"
          @action_delete="mtd_delete"
        />
      </CCol>
    </CRow>

    <!-- modal asignación -->
    <CModalForm
      :size="'md'"
      :title="modal_asig.title"
      :show.sync="modal_asig.modal_form"
      @mtd_action="mtd_action_asig"
    >
      <CRow>
        <CCol lg="6" md="6" sm="12" xs="12">
          <div
            :class="
              modal_asig.asignation == 2
                ? 'card text-white bg-info text-center'
                : 'card text-white bg-secondary text-center'
            "
            @click="mtd_setasig(2)"
          >
            <div class="card-body">
              <div class="pull-right pt-10 pr-2"></div>
              <div class="h4 m-0">PARA VENTA</div>
            </div>
          </div>
        </CCol>
        <CCol lg="6" md="6" sm="12" xs="12">
          <div
            :class="
              modal_asig.asignation == 3
                ? 'card text-white bg-info text-center'
                : 'card text-white bg-secondary text-center'
            "
            @click="mtd_setasig(3)"
          >
            <div class="card-body">
              <div class="pull-right pt-10 pr-2"></div>
              <div class="h4 m-0">PARA PROCESAR</div>
            </div>
          </div>
        </CCol>
      </CRow>
    </CModalForm>
    <!-- modal entrega -->
    <CModalForm
      :size="'md'"
      :title="modal_entrega.title"
      :show.sync="modal_entrega.modal_form"
      @mtd_action="mtd_action_entrega"
      :button="cp_button_entrega"
    >
      <CRow>
        <CCol lg="12" md="12" sm="12" xs="12">
          <CInput
            type="date"
            label="Fecha de entrega"
            v-model="modal_entrega.date"
          />
        </CCol>
        <CCol lg="12" md="12" sm="12" xs="12">
          <CInput
            label="Comprobante"
            v-model="modal_entrega.document"
            placeholder="Digite comprobante de entrega"
          />
        </CCol>
        <CCol lg="12" md="12" sm="12" xs="12">
          <CInput
            label="Peso bruto"
            v-model="modal_entrega.peso_bruto"
            placeholder="Digite Peso bruto de entrega"
            v-on:keypress="isNumber($event)"
          />
        </CCol>
        <CCol lg="12" md="12" sm="12" xs="12">
          <CInput
            label="Número de sacos"
            v-model="modal_entrega.sacos"
            placeholder="Digite número de  sacos de entrega"
            v-on:keypress="isNumber($event)"
          />
        </CCol>
        <CCol lg="12" md="12" sm="12" xs="12">
          <CInput
            label="Peso neto"
            v-model="cp_entrega_neto"
            :disabled="true"
          />
        </CCol>
      </CRow>
    </CModalForm>

    <!-- modal valores -->
    <CModalForm
      :size="'lg'"
      :title="modal_valores.title"
      :show.sync="modal_valores.modal_form"
      @mtd_action="mtd_action_valores"
    >
      <CRow>
        <CCol col="12">
          <table
            class="
              table              
              table-fixed
              table-hover
              table-bordered
            "
          >
            <thead style="background-color: rgb(100 172 226);">
              <tr>
                <th style="vertical-align: middle;" rowspan="2">N°</th>
                <th style="vertical-align: middle;" rowspan="2">Tipo café</th>
                <th style="vertical-align: middle;" rowspan="2">Peso neto</th>
                <th style="vertical-align: middle;" rowspan="2">QQ de 46Kg</th>
                <th style="text-align: center;" colspan="2">Stock</th>
                <th style="text-align: center;" colspan="2">% de rendimiento</th>
              </tr>
              <tr>
                <th>SC de 69Kg</th>
                <th>Kg</th>
                <th>Kg</th>
                <th>QQ</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in modal_table" :key="index">
                <td style="text-align:center;"> {{index + 1}}</td>
                <td>{{item.name}}</td>
                <td>
                  <CInput
                    size="sm"
                    label=""
                    placeholder="Digite peso neto"
                    v-model="item.neto"
                    @blur="mtd_out_focus(index)"
                    v-on:keypress="isNumber($event)"
                    v-on:keyup.enter="mtd_out_focus(index)"
                  />
                </td>
                <td>{{item.qq_46}} </td>
                <td>{{item.sc_69}} </td>
                <td>{{item.kg}} </td>
                <td>{{item.kg_ren}} </td>
                <td>{{item.qq_ren}} </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="2" style="text-align: center;">TOTAL GENERAL</td>
                <td style="text-align: center;font-weight: bold;">{{cp_total_neto}}</td>
                <td style="text-align: center;font-weight: bold;">{{cp_total_qq46}}</td>
                <td style="text-align: center;font-weight: bold;">{{cp_total_sc69}}</td>
                <td style="text-align: center;font-weight: bold;">{{cp_total_kgstock}}</td>
                <td style="text-align: center;font-weight: bold;">{{cp_total_kgren}}</td>
                <td style="text-align: center;font-weight: bold;">{{cp_total_qqren}}</td>
              </tr>
            </tfoot>
          </table>
        </CCol>
      </CRow>
    </CModalForm>

    
    <!-- modal delete -->
    <cModalDelete
      title="Borrar Lote"
      :boo_modal="modal_delete.boo"
      :item="modal_delete.item"
      @close_delete="close_delete"
      @mtd_commit="mtd_commit"
    ></cModalDelete>
  </div>
</template>

<script>
const fields = [
  { key: "Id", label: "Id", _style: "width:3%" },
  { key: "certification", label: "Certificaciones", _style: "width:3%" },
  { key: "asignation", label: "Asignado", _style: "width:3%" },
  { key: "date", label: "Fecha", _style: "width:10%;" },
  { key: "lote", label: "Lote", _style: "width:10%;" },
  { key: "destination", label: "Destino", _style: "width:15%;" },
  { key: "transport", label: "Transportista", _style: "width:15%;" },
  { key: "remision", label: "G. remision", _style: "width:15%;" },
  { key: "transportista", label: "G. transportista", _style: "width:15%;" },
  { key: "reference", label: "Referencia", _style: "width:20%;" },
  { key: "responsable", label: "Responsable", _style: "width:20%;" },
  // { key: "detalle", label: "Detalle", _style: "width:20%;" },
  // { key: "neto", label: "Peso neto", _style: "width:20%;" },
  // { key: "humedad", label: "Humedad", _style: "width:20%;" },
  // { key: "bruto", label: "Peso bruto", _style: "width:20%;" },
  // { key: "sacos", label: "Num. sacos", _style: "width:20%;" },
];

import CTableWrapper from "../../components/shared/datatable/Table.vue";
import CModalForm from "../../components/shared/modals/cModalForm.vue";
import cModalDelete from "../../components/shared/modals/cModalDelete.vue";
import { mapActions } from "vuex";
import { bus } from "../../main";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  components: { CTableWrapper, cModalDelete, vSelect, CModalForm },
  data() {
    return {
      prefix: "lote",
      fields,
      data: [],
      data_product_entry: [],
      data_destinations: [],
      data_transports: [],
      data_asig: [
        { value: 1, label: "Registrado" },
        { value: 2, label: "Para venta" },
        { value: 3, label: "Para procesar" },
      ],
      filter: {
        product_entry: [],
        destination: [],
        transport: [],
        asig: null,
        date_initial: "",
        date_end: "",
      },
      myButtons: [
        {
          class: "btn-sm",
          color: "success",
          tooltip: "Asignar LOTE",
          action: "mtd_asignar_lote",
          icon: "fas fa-arrows-alt",
          show: {
            0: false,
            1: true,
            2: false,
            3: false,
          },
        },
        {
          class: "btn-sm",
          color: "info",
          tooltip: "Registro de entrege",
          action: "mtd_reg_entrega",
          icon: "fas fa-book",
          show: {
            0: false,
            1: false,
            2: true,
            3: false,
          },
        },
        {
          class: "btn-sm",
          color: "info",
          tooltip: "Agregar valores",
          action: "mtd_set_valore_proceso",
          icon: "fas fa-comment-medical",
          show: {
            0: false,
            1: false,
            2: false,
            3: true,
          },
        },
      ],
      // load: {
      //   excel: false,
      // },
      modal_asig: {
        action: "",
        title: "ASIGNAR EL LOTE",
        modal_form: false,
        id: "",
        asignation: 0,
      },
      /** modal reg entega */
      modal_entrega: {
        action: "",
        title: "REGISTRO DE ENTREGA",
        modal_form: false,
        id: "",
        date:null,
        document:"",
        peso_bruto:"",
        sacos:"",
        peso_neto:""
      },
      /** modal valores */
      modal_valores: {
        action: "",
        title: "REGISTRAR VALORES DE PROCESO",
        modal_form: false,
        id: "",
      },
      lot_amount : 0,
      lot_qq:0,
      modal_table:[
        {
          'name':'KD-EXPORTABLE',
          'neto':0,
          'sc_69':0,
          'kg':0,
          'qq_46':0,
          'kg_ren':0,
          'qq_ren':0 
        },
        {
          'name':'KD-SEGUNDA',
          'neto':0,
          'sc_69':0,
          'kg':0,
          'qq_46':0,
          'kg_ren':0,
          'qq_ren':0 
        },
        {
          'name':'KD-TERCERA',
          'neto':0,
          'sc_69':0,
          'kg':0,
          'qq_46':0,
          'kg_ren':0,
          'qq_ren':0 
        },
        {
          'name':'KD-ESCOJO',
          'neto':0,
          'sc_69':0,
          'kg':0,
          'qq_46':0,
          'kg_ren':0,
          'qq_ren':0 
        },
        {
          'name':'KD-MENUDO',
          'neto':0,
          'sc_69':0,
          'kg':0,
          'qq_46':0,
          'kg_ren':0,
          'qq_ren':0 
        },
        {
          'name':'KD-COCO',
          'neto':0,
          'sc_69':0,
          'kg':0,
          'qq_46':0,
          'kg_ren':0,
          'qq_ren':0 
        },
        {
          'name':'KD-CASCARA-IMP',
          'neto':0,
          'sc_69':0,
          'kg':0,
          'qq_46':0,
          'kg_ren':0,
          'qq_ren':0 
        },
      ],
      boo_process:false,
      /** modal delete */
      modal_delete: {
        boo: false,
        item: [],
      },
    };
  },
  computed: {
    cp_entrega_neto:function(){
      if (this.modal_entrega.peso_bruto > 0 && this.modal_entrega.sacos > 0) {
        let op1 = parseFloat(this.modal_entrega.sacos * 0.25);
        let res = parseFloat(this.modal_entrega.peso_bruto - op1).toString();
        let ar = res.split(".");
        let new_decimal = ar[1].substring(0, 2);
        let new_res = ar[0] + "." + new_decimal;
        this.modal_entrega.peso_neto = parseFloat(new_res);
        return parseFloat(new_res);
      }else{
        return 0;
      }
    },
    cp_button_entrega: function(){
      if (this.modal_entrega.document.length > 0 && this.modal_entrega.peso_bruto > 0 && this.modal_entrega.peso_neto > 0 && this.modal_entrega.peso_bruto != "" && this.modal_entrega.peso_neto != "") return false;
      return true;
    },
    /** valores */
    cp_total_neto: function(){
      let tot = 0;      
      this.modal_table.forEach(element => {
        let t = (element.neto.length) == 0? 0: element.neto;
        tot = parseFloat(tot) + parseFloat(t);
      });
      if (parseFloat(tot) > parseFloat(this.lot_amount) ) {
        bus.$emit("alert", {
          color: "warning",
          message: "EL MONTO NO DEBE SER MAYOR AL PESO NETO DEL LOTE",
        });
        this.boo_process =false;
      }else{
        this.boo_process=true;
      }
      return parseFloat(tot).toFixed(2);
    },
    cp_total_qq46: function(){
      let tot = 0;
      this.modal_table.forEach(element => {
        tot = parseFloat(tot) + parseFloat(element.qq_46);
      });
      return parseFloat(tot).toFixed(2);
    },
    cp_total_sc69: function(){
      let tot = 0;
      this.modal_table.forEach(element => {
        tot = parseFloat(tot) + parseFloat(element.sc_69);
      });
      return parseFloat(tot).toFixed(2);
    },
    cp_total_kgstock: function(){
      let tot = 0;
      this.modal_table.forEach(element => {
        tot = parseFloat(tot) + parseFloat(element.kg);
      });
      return parseFloat(tot).toFixed(2);
    },
    cp_total_kgren: function(){
      let tot = 0;
      this.modal_table.forEach(element => {
        tot = parseFloat(tot) + parseFloat(element.kg_ren);
      });
      return parseFloat(tot).toFixed(2);
    },
    cp_total_qqren: function(){
      let tot = 0;
      this.modal_table.forEach(element => {
        tot = parseFloat(tot) + parseFloat(element.qq_ren);
      });
      return parseFloat(tot).toFixed(2);
    },
  },
  created() {
    let actual = new Date();
    let fecha;
    fecha =
      actual.getFullYear() +
      "-" +
      ("0" + (actual.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + actual.getDate()).slice(-2);
    // this.filter.date_initial = fecha;
    this.filter.date_end = fecha;
    this.modal_entrega.date = fecha;

    let temp = new Date();
    temp.setDate(temp.getDate() - 5);
    this.filter.date_initial =
      temp.getFullYear() +
      "-" +
      ("0" + (temp.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + temp.getDate()).slice(-2);
    // this.mtd_search_filter();
    this.mtd_getdata();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_getdata: function () {
      this.get({
        url: this.$store.getters.get__url + "/" + this.prefix,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data_product_entry = response.data_product_entry;
          this.data_destinations = response.data_destinations;
          this.data_transports = response.data_transports;
          this.mtd_search_filter();
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtd_send_register: function () {
      this.$router.push({ path: "acopio/register" });
    },
    mtd_search_filter: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/searchFilter",
        token: this.$store.getters.get__token,
        params: this.filter,
      })
        .then((response) => {
          if (response.state == 0) {
            this.data = response.data.map((item, order) => {
              return { ...item, order };
            });

            // if (this.data.length >= 1) {
            //   this.load.excel = true;
            // } else {
            //   this.load.excel = false;
            // }
          } else {
            bus.$emit("alert", {
              color: "danger",
              message: "EL DOCUMENTO NO EXISTE",
            });
          }
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    /** asignaciones */
    mtd_asignar_lote: function (item) {
      this.modal_asig.modal_form = true;
      this.modal_asig.asignation = 0;
      this.modal_asig.id = item.Id;
    },
    mtd_setasig: function (value) {
      this.modal_asig.asignation = value;
    },
    mtd_action_asig: function () {
      if (this.modal_asig.asignation != 0) {
        this.post({
          url:
            this.$store.getters.get__url + "/" + this.prefix + "/setasignation",
          token: this.$store.getters.get__token,
          params: this.modal_asig,
        })
          .then((response) => {
            if (response.state == 0) {
              this.modal_asig = {
                action: "",
                title: "ASIGNAR EL LOTE",
                modal_form: false,
                id: "",
                asignation: 0,
              };
              bus.$emit("alert", {
                color: "success",
                message: "ASIGNADO CORRECTAMENTE",
              });
              this.mtd_search_filter();
            } else {
              bus.$emit("alert", {
                color: "danger",
                message: response.message,
              });
            }
          })
          .catch((errors) => {
            // this.errorsBackend = errors;
            // this.$emit("error", this.event);
          });
      } else {
        bus.$emit("alert", {
          color: "warning",
          message: "SELECCIONE UNA OPCIÓN",
        });
      }
    },
    /** registro de entrega */
    mtd_reg_entrega: function(item){
      this.modal_entrega.modal_form = true;
      this.modal_entrega.id = item.Id;
    },
    mtd_action_entrega: function(){
      this.post({
          url:
            this.$store.getters.get__url + "/" + this.prefix + "/entrega/store",
          token: this.$store.getters.get__token,
          params: this.modal_entrega
        })
          .then((response) => {
            if (response.state == 0) {
              this.modal_entrega = {
                action: "",
                title: "REGISTRO DE ENTREGA",
                modal_form: false,
                id: "",
                date:null,
                document:"",
                peso_bruto:"",
                sacos:"",
                peso_neto:""
              };
              bus.$emit("alert", {
                color: "success",
                message: "REGISTRADO CORRECTAMENTE",
              });
              this.mtd_search_filter();
            } else {
              bus.$emit("alert", {
                color: "danger",
                message: response.exception,
              });
            }
          })
          .catch((errors) => {
             bus.$emit("alert", {
                color: "danger",
                message: errors,
              });
          });
    },
    /** valores de proceso */
    mtd_set_valore_proceso: function(item){
      this.modal_table = [
        {
          'name':'KD-EXPORTABLE',
          'neto':0,
          'sc_69':0,
          'kg':0,
          'qq_46':0,
          'kg_ren':0,
          'qq_ren':0 
        },
        {
          'name':'KD-SEGUNDA',
          'neto':0,
          'sc_69':0,
          'kg':0,
          'qq_46':0,
          'kg_ren':0,
          'qq_ren':0 
        },
        {
          'name':'KD-TERCERA',
          'neto':0,
          'sc_69':0,
          'kg':0,
          'qq_46':0,
          'kg_ren':0,
          'qq_ren':0 
        },
        {
          'name':'KD-ESCOJO',
          'neto':0,
          'sc_69':0,
          'kg':0,
          'qq_46':0,
          'kg_ren':0,
          'qq_ren':0 
        },
        {
          'name':'KD-MENUDO',
          'neto':0,
          'sc_69':0,
          'kg':0,
          'qq_46':0,
          'kg_ren':0,
          'qq_ren':0 
        },
        {
          'name':'KD-COCO',
          'neto':0,
          'sc_69':0,
          'kg':0,
          'qq_46':0,
          'kg_ren':0,
          'qq_ren':0 
        },
        {
          'name':'KD-CASCARA-IMP',
          'neto':0,
          'sc_69':0,
          'kg':0,
          'qq_46':0,
          'kg_ren':0,
          'qq_ren':0 
        },
      ];
      this.modal_valores.modal_form = true;
      this.modal_valores.id = item.Id;
      this.lot_amount = parseFloat(item.amount).toFixed(2);
      this.lot_qq = parseFloat(this.lot_amount/46).toFixed(2);
      this.modal_valores.title = 'REGISTRAR VALORES DE PROCESO - PESO NETO DEL LOTE '+parseFloat(this.lot_amount).toFixed(2)+"Kg";
    },
    mtd_out_focus: function(pos){
      let neto = this.modal_table[pos].neto.length == 0?0: parseFloat( this.modal_table[pos].neto).toFixed(2);
      this.modal_table[pos].neto = neto;
      let qq_46 = parseFloat(neto/46).toFixed(2);
      this.modal_table[pos].qq_46 = qq_46;
      let sc_69 = parseInt(neto/69);
      this.modal_table[pos].sc_69 = sc_69;
      let dif = parseFloat( neto - (sc_69*69) ).toFixed(2);
      this.modal_table[pos].kg = dif;
      this.modal_table[pos].kg_ren = parseFloat( (neto*100)/this.lot_amount).toFixed(2);
      this.modal_table[pos].qq_ren = parseFloat( (qq_46*100)/this.lot_qq).toFixed(2);
    },
    mtd_action_valores: function(){
      if (this.boo_process == true) {
        this.post({
          url:
            this.$store.getters.get__url + "/" + this.prefix + "/process/store",
          token: this.$store.getters.get__token,
          params: {
            lot_id: this.modal_valores.id,
            data: this.modal_table,
          },
        })
          .then((response) => {
            if (response.state == 0) {
              this.modal_table = [
                {
                  'name':'KD-EXPORTABLE',
                  'neto':0,
                  'sc_69':0,
                  'kg':0,
                  'qq_46':0,
                  'kg_ren':0,
                  'qq_ren':0 
                },
                {
                  'name':'KD-SEGUNDA',
                  'neto':0,
                  'sc_69':0,
                  'kg':0,
                  'qq_46':0,
                  'kg_ren':0,
                  'qq_ren':0 
                },
                {
                  'name':'KD-TERCERA',
                  'neto':0,
                  'sc_69':0,
                  'kg':0,
                  'qq_46':0,
                  'kg_ren':0,
                  'qq_ren':0 
                },
                {
                  'name':'KD-ESCOJO',
                  'neto':0,
                  'sc_69':0,
                  'kg':0,
                  'qq_46':0,
                  'kg_ren':0,
                  'qq_ren':0 
                },
                {
                  'name':'KD-MENUDO',
                  'neto':0,
                  'sc_69':0,
                  'kg':0,
                  'qq_46':0,
                  'kg_ren':0,
                  'qq_ren':0 
                },
                {
                  'name':'KD-COCO',
                  'neto':0,
                  'sc_69':0,
                  'kg':0,
                  'qq_46':0,
                  'kg_ren':0,
                  'qq_ren':0 
                },
                {
                  'name':'KD-CASCARA-IMP',
                  'neto':0,
                  'sc_69':0,
                  'kg':0,
                  'qq_46':0,
                  'kg_ren':0,
                  'qq_ren':0 
                },
              ];
              this.modal_valores = {
                action: "",
                title: "REGISTRAR VALORES DE PROCESO",
                modal_form: false,
                id: "",
              };
              bus.$emit("alert", {
                color: "success",
                message: "AGREGADO CORRECTAMENTE",
              });
              this.mtd_search_filter();
            } else {
              bus.$emit("alert", {
                color: "danger",
                message: response.message,
              });
            }
          })
          .catch((errors) => {
             bus.$emit("alert", {
                color: "danger",
                message: errors,
              });
          });
      } else {
        bus.$emit("alert", {
          color: "warning",
          message: "EL MONTO DEBE SER MAYOR AL PESO NETO DEL LOTE",
        });
      }
    },
    /** delete */
    mtd_delete: function (item) {
      this.modal_delete.boo = true;
      this.modal_delete.item = item;
    },
    close_delete: function () {
      this.modal_delete.boo = false;
    },
    mtd_commit: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/destroy",
        token: this.$store.getters.get__token,
        params: {
          id: this.modal_delete.item.Id,
        },
      })
        .then((response) => {
          if (response.state == 0) {
            bus.$emit("alert", {
              color: "success",
              message: "ELIMINADO CORRECTAMENTE!!",
            });  
            this.mtd_search_filter();
          }else if(response.state == 2){
            bus.$emit("alert", {
              color: "warning",
              message: response.message,
            });  
          }else{
            bus.$emit("alert", {
              color: "danger",
              message: response.message,
            });  
          }
          this.close_delete();
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },

    /** helpers */
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    /** aun no se usan */
    mtd_export_excel: function () {
      this.post({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/" +
          "GenerateEXCEL",
        token: this.$store.getters.get__token,
        params: this.filter,
      })
        .then((response) => {
          this.download(response);
        })
        .catch((errors) => {
          // console.log(errors);
        });
    },
    // descarga el archivo
    download: function (data) {
      if (!data) {
        return;
      }
      let url = window.URL.createObjectURL(new Blob([data]));
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", "acopio.xlsx");
      document.body.appendChild(link);
      link.click();
    },
    mtd_export_pdf: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/GeneratePDF",
        token: this.$store.getters.get__token,
        params: this.filter,
      })
        .then((response) => {
          window.open("/assets/sales/" + response + ".pdf", "_blank");
        })
        .catch((errors) => {
          // console.log(errors);
        });
    },
    mtd_view_pdf: function (item) {
      window.open("/assets/acopio/" + item.uuid + ".pdf", "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
.table th,
.table td {
  padding: 0.2rem;
  vertical-align: middle;
}
</style>